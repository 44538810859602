@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'globals';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


//*******************************************************************
//*******************************************************************
//*******************************************************************
// Rajout CP
//*******************************************************************
//*******************************************************************
//*******************************************************************


//*******************************************************************
// DÉBUT thème global
//*******************************************************************

body, html {
  background-color: $black;
  height: 100%;
  background-image: url('/assets/img/fond-corps.jpg');
  background-repeat: repeat-x repeat-y;
}

body {
  min-height: 100%;
  margin: 0;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  overflow-x: hidden;
  background-image: url('/assets/img/fond-corps.jpg') repeat-x;
}

canvas {
  display: block;
  margin: 0px auto 20px;
  cursor: move;
}

.mon-conteneur {
  padding: 1rem;
  margin: 0rem;
}

.ma-grille {
  @include xy-grid;
  $grid-columns: 24;
  $xy-block-grid-max: 4;
}

$arrondi-global: 0.6rem;
$couleur-top-bar: #f3f3f3;

.conteneur-central {
  margin: 5%;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 100%;
  background: url('/assets/img/conteneur-central.png');
  // -webkit-border-image: url('/assets/img/separateur-vertical-noir-carbone-02.png') 32 / 6px 6px 6px 6px / 6px 6px 6px 6px round;
  // -moz-border-image: url('/assets/img/separateur-vertical-noir-carbone-02.png') 32 / 6px 6px 6px 6px / 6px 6px 6px 6px round;
  border-image: url("/assets/img/bordure-conteneur-central.png") 32 / 12px 12px 12px 12px / 12px 12px 12px 12px round;
}

.separateur-top-bar {
  margin: 0;
  background: url('/assets/img/separateur-top-bar.png') left top repeat-x ;
  width: 100%;
  height: 8px;
  z-index: 100;
}

//*******************************************************************
// FIN thème global
//*******************************************************************


//*******************************************************************
// DÉBUT section media-queries
//*******************************************************************

/* Small only */
@media screen and (max-width: 39.9375em) {
  
}

/* up to Medium */
@media screen and (max-width: 63.9375em) {
  .padding-adapte {
    padding: var(--monpaddingsmall);
    padding-top: var(--monpadtopsmall);
  }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .padding-adapte {
    padding: var(--monpaddingmedium);
    padding-top: var(--monpadtopmedium);
  }
}

/* Medium and up */
@media screen and (min-width: 40em) {
  
}

/* up to Large */
@media screen and (max-width: 74.9375em) {
  
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  
}

/* Large and up */
@media screen and (min-width: 64em) {
  .padding-adapte {
    padding: var(--monpaddinglarge);
    padding-top: var(--monpadtoplarge);
  }
}

//*******************************************************************
// FIN section media-queries
//*******************************************************************


//*******************************************************************
// DÉBUT section boutons
//*******************************************************************


@mixin radius-left($radius) {
    border-radius-topleft:              $radius;
    border-radius-bottomleft:           $radius;
    -moz-border-radius-topleft:         $radius;
    -moz-border-radius-bottomleft:      $radius;
    -webkit-border-top-left-radius:     $radius;
    -webkit-border-bottom-left-radius:  $radius;
    -khtml-border-top-left-radius:      $radius;
    -khtml-border-bottom-left-radius:   $radius;
}

@mixin radius-right($radius) {
    border-radius-topright:             $radius;
    border-radius-bottomright:          $radius;
    -moz-border-radius-topright:        $radius;
    -moz-border-radius-bottomright:     $radius;
    -webkit-border-top-right-radius:    $radius;
    -webkit-border-bottom-right-radius: $radius;
    -khtml-border-top-right-radius:     $radius;
    -khtml-border-bottom-right-radius:  $radius;
}

.arrondi-fleche-droite { @include radius-left(35px); }
.arrondi-fleche-droite { @include radius-right(90px); }

.arrondi-fleche-gauche { @include radius-left(90px); }
.arrondi-fleche-gauche { @include radius-right(35px); }

.arrondi { @include radius-left(90px); }
.arrondi { @include radius-right(90px); }

.bouton-lang {
  width: 5rem;
}

.bouton-style-01 {
  color: $body-font-color;
  border-radius: $arrondi-global;
  background: rgba($couleur-theme-site, 0.6);
  margin: 3px;
}

.bouton-style-01:hover {
  color: rgba($body-font-color, 0.8);
  border-radius: $arrondi-global;
  background: rgba($couleur-theme-site, 0.4);
}

.bouton-style-02 {
  color: $body-font-color;
  border-radius: $arrondi-global;
  background: rgba($couleur-fond-cadre, 0.6);
  margin: 3px;
}

.bouton-style-02:hover {
  color: rgba($body-font-color, 0.8);
  border-radius: $arrondi-global;
  background: rgba($couleur-fond-cadre, 0.4);
}
//*******************************************************************
// FIN section boutons
//*******************************************************************


//*******************************************************************
// DÉBUT section accordéon
//*******************************************************************

.accordion.mon-accordeon {
  font-size: $taille-texte-accordeon-contenu;
  border-radius: $arrondi-global;
  background: transparent !important;
}

.accordion-title.mon-accordeon {
  background-color: $couleur-accordeon-titre-fond;
  border: 2px solid $couleur-accordeon-titre-bordure;
  border-bottom: 2px solid $couleur-accordeon-titre-bordure;
  font-size: $taille-texte-accordeon-titre;
  color: $couleur-accordeon-titre-texte;
}

.accordion-title.mon-accordeon:focus {
  background-color: $couleur-accordeon-titre-actif-courant;
  color: $couleur-accordeon-titre-texte;
}

.accordion-title.mon-accordeon:hover {
  background-color: $couleur-accordeon-titre-focus-souris;
  color: $couleur-accordeon-titre-texte;
}

.accordion-item.mon-accordeon {
  //border-radius: 0 0 0 0;
}

.accordion-item.mon-accordeon:last-child {
  //border-radius: 3rem;
}

.accordion-container.mon-accordeon {
//  border-radius: $arrondi-global;
}

.accordion-content.mon-accordeon {
  border-radius: $arrondi-global;
  border: 2px solid $couleur-accordeon-contenu-bordure;
//  background-image: url('/assets/img/fond-accordeon.jpg');
//  background-repeat: repeat repeat;
  background: transparent !important;
  color: $couleur-accordeon-contenu-texte;
  margin: 5px;

  :first-child > &:first-child {
    border-radius: $arrondi-global;
    border: 2px solid $couleur-accordeon-contenu-bordure;
    margin: 5px;
  }

  :last-child > &:last-child {
    border-radius: $arrondi-global;
    border: 2px solid $couleur-accordeon-contenu-bordure;
    margin: 5px;
  }

}

//*******************************************************************
// FIN section accordéon
//*******************************************************************


//*******************************************************************
// DÉBUT section tabulations
//*******************************************************************

// DÉBUT classe "ta-tabulation"
.tabs.ta-tabulation {
  border: 6px solid green;
  color: white;
}

.tabs-content.ta-tabulation.vertical {
  border-color: yellow;
}
// FIN classe "ta-tabulation"


// DÉBUT classe "ma-tabulation"
.tabs.ma-tabulation.vertical {
  border-radius: $arrondi-global;
  border: 2px solid $couleur-tabulation-titre-bordure;
  border-bottom: 2px solid $couleur-tabulation-titre-bordure;
  font-size: $taille-texte-tabulation-titre;
  background: transparent;
  justify-content: space-between;
}

.tabs.ma-tabulation.horizontal {
  border-radius: $arrondi-global;
  border: 2px solid $couleur-tabulation-titre-bordure;
  border-bottom: 2px solid $couleur-tabulation-titre-bordure;
  font-size: $taille-texte-tabulation-titre;
  background: transparent;
  justify-content: space-between;
  display: flex;
}

.tabs-content.ma-tabulation {
  border-radius: $arrondi-global;
  border: 2px solid $couleur-tabulation-contenu-bordure;
  background: transparent !important;
  margin: 5px;
}

.tabs-title.ma-tabulation {
  flex: 1 0 0;
  text-align: center;
}

/* BUG : désactive la couleur d'arrière-plan active lorsqu'on sort du tab
.tabs-title.ma-tabulation a {
  background: transparent !important;
}
*/

.tabs-title.ma-tabulation a:hover {
  background: $couleur-tabulation-titre-focus-souris !important;
}

.tabs-title.ma-tabulation a:focus {
  background: $couleur-tabulation-titre-actif-courant !important;
}

.tabs-title.ma-tabulation a:active {
  background: $couleur-tabulation-titre-actif-courant !important;
}
// FIN classe "ma-tabulation"

//*******************************************************************
// FIN section tabulations
//*******************************************************************


//*******************************************************************
// DÉBUT section traitement images
//*******************************************************************

.image-sombre a:hover {
  opacity: 0.8;
}

.mon-thumbnail {
  border: 3px solid $couleur-texte;
  border-radius: $arrondi-global;
  margin-bottom: 0.5rem;
}

//*******************************************************************
// FIN section traitement images
//*******************************************************************


//*******************************************************************
// DÉBUT section barre de navigation
//*******************************************************************

.position-title-bar {
  top: 0;
  z-index: 90;
}

.title-bar {
  display: table;
  width: 100%;
  vertical-align: middle;
}

.title-bar-left, .title-bar-right {
  float: none;
  display: table-cell;
}

.top-bar {
  background: url('/assets/img/fond-top-bar.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: auto;
  padding: 0.5rem;
  border-radius: 0px;
  width: 100%;
  position: fixed;
}

.top-bar ul {
  background-color: transparent !important;
}

.top-bar-left .bouton-menu-02 {
  color: $body-font-color;
  border-radius: $arrondi-global;
  background: rgba($orbit-couleur-fleche, 0.4);
  margin: 3px;
}

.top-bar-left .bouton-menu-02:hover {
  color: rgba($body-font-color, 0.8);
  background: rgba($orbit-couleur-fleche, 0.2);
}

.top-bar-left a {
  color: $couleur-top-bar;
}

.top-bar-left a:hover {
  color: rgba($couleur-top-bar, 0.8);
  background: rgba(get-color(primary), 0.2);
}

.top-bar-left ul .mon-divider {
  border-bottom: none;
  border-top: none;
  border-right: solid 2px;
  clear: none;
  height: 36px;
  color: $couleur-top-bar;
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.coin-bas-droite {
  bottom: 5px;
  right: 5px;
  position: fixed;
}

//*******************************************************************
// FIN section barre de navigation
//*******************************************************************


//*******************************************************************
// DÉBUT section barre à étapes
//*******************************************************************

@mixin step-progessbar($largeur) {
  width: $largeur;
}

.container {
  margin: 30px auto;
}

.container-large {
// ATTENTION : ajuster en fonction de .progressbar li:after { left: }
  @include step-progessbar(600px);
}

.container-etroit {
// ATTENTION : ajuster en fonction de .progressbar li:after { left: }
  @include step-progessbar(300px);
}

.progressbar {
  counter-reset: step;
}

.progressbar li {
// ATTENTION : width = 100% / nombre d'étapes
  width: 20%;
}

.progressbar li {
  list-style-type: none;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
  z-index: 1;
}

.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #2d2d2d;
  z-index: -1;
}

.progressbar li:after {
  width: 100%;
  height: 4px;
  content: ' ';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  z-index: -1;
}

.progressbar-large li:after {
// ATTENTION : ajuster en fonction de .container-(large,etroit)
  left: -38%;
}

.progressbar-etroit li:after {
// ATTENTION : ajuster en fonction de .container-(large,etroit)
  left: -24%;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: $body-font-color;
}

.progressbar li.active:before {
  border-color: $body-font-color;
}
.progressbar li.active + li:after {
  background-color: $body-font-color;
}

//*******************************************************************
// FIN section barre à étapes
//*******************************************************************


//*******************************************************************
// DÉBUT section texte
//*******************************************************************

// DÉBUT texte surbrillant
.texte-surbrillant {
  color: $couleur-titre-bordure;
}

// DÉBUT texte monospace
.texte01 {
  font-family: "Courier New", monospace;
}

.texte02 {
  font-family: "Lucida Console", "Courier New", monospace;
}

.texte03 {
  font-family: "Monaco", "Courier New", monospace;
}
// FIN texte monospace

// DÉBUT texte non-sérifié
.texte11 {
  font-family: Arial, sans-serif;
}

.texte12 {
  font-family: Helvetica, Arial, sans-serif;
}

.texte13 {
  font-family: Verdana, Arial, sans-serif;
}
// FIN texte non-sérifié

// DÉBUT texte sérifié
.texte21 {
  font-family: Times, serif;
}

.texte22 {
  font-family: "Times New Roman", Times, serif;
}

.texte23 {
  font-family: Georgia, Times, serif;
}

.texte24 {
  font-family: Garamond, Times, serif;
}
// FIN texte sérifié

// DÉBUT texte cursif
.texte31 {
  font-family: "Brush Script MT", Times, serif;
}

.texte32 {
  font-family: "Lucida Handwriting", Times, serif;
}

// FIN texte cursif

// DÉBUT texte fantaisiste
.texte41 {
  font-family: Copperlate, Times, serif;
}

.texte42 {
  font-family: Papyrus, Times, serif;
}
// FIN texte fantaisiste

// DÉBUT texte couleur négative
.texte-negatif {
  color: $couleur-texte-negatif;
}

//*******************************************************************
// FIN section texte
//*******************************************************************


//*******************************************************************
// DÉBUT section paragraphe
//*******************************************************************

.p-adapte {
  margin-left: 3rem;
  line-height: 1.2rem;
  text-indent: 3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.cell-gauche {
  padding-top: 1.1rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  border-right: 1px dashed $couleur-texte;
  background-color: $couleur-fond-cadre;
}

.cell-droite {
  padding-top: 1.1rem;
  padding-bottom: 2.2rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  border-left: 1px dashed $couleur-texte;
  background-color: rgba($couleur-texte, 0.2);
}

.surlignage-01 {
  size: shrink;
  background-color: yellow;
  padding: 10px;
  border-radius: 4px;
  color: black;
}

.surlignage-02 {
  size: shrink;
  background-color: rgba($orbit-couleur-fleche, 0.6);
  padding: 10px;
  border-radius: 4px;
}

.bordure {
  border: 2px solid $couleur-titre-bordure;
}

.contraste-01 {
   background-color: rgba($black, 0.6);
   border-radius: 6px;
   margin: 6px;
}

//*******************************************************************
// FIN section paragraphe
//*******************************************************************


//*******************************************************************
// DÉBUT section Orbit Carroussel d'images
//*******************************************************************

.mon-orbit-image {
  border-radius: $arrondi-global;
}

.orbit-previous {
  color: $orbit-couleur-fleche;
  background: rgba($orbit-couleur-fleche, 0.33);
  z-index: 1;
}

.orbit-next {
  color: $orbit-couleur-fleche;
  background: rgba($orbit-couleur-fleche, 0.33);
  z-index: 1;
}

//*******************************************************************
// FIN section Orbit Carroussel d'images
//*******************************************************************


//*******************************************************************
// DÉBUT section divisions spéciales
//*******************************************************************

.flottant {
  position: fixed;
  z-index: 100;
}

.video-flottante {
  top: 10px;
  left: 5px;
  position: fixed;
  margin: 0px;
  padding-top: 15px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 0px;
  background-color: rgba($couleur-top-bar, 0.6);
}

.image-fond-division {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important; 
}

.image-fond-contenu {
  background-position: top center !important;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  min-height: auto;
  padding: 0.5rem;
  border-radius: 0px;
  width: 100%;
  position: none !important;
  display: table-cell !important;
}

.cadre-bordure-01 {
  border: 2px solid $couleur-titre-bordure;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: $arrondi-global;
}

.cadre-bordure-02 {
  border: 2px solid $couleur-bordure;
  padding: 0.5rem;
  margin: 0.5rem;
  margin-top: 1rem;
  border-radius: $arrondi-global;
}

.conteneur-superposition {
  width: 100%;
  height: 100%;
  position: relative;
}

.fond {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.surface-01 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}

.surface-02 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
}

//*******************************************************************
// FIN section divisions spéciales
//*******************************************************************


//*******************************************************************
// DÉBUT section reveal
//*******************************************************************

.banniere-top {
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.banniere-top p {
  color: black;
  font-family: 'Arial';
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  padding-left: 1.1rem;
  padding-right: 1.1rem;
  background-color: rgba($couleur-top-bar, 0.6);
}

.banniere-top large {
  background-position: center center;
}


//*******************************************************************
// FIN section reveal
//*******************************************************************


//*******************************************************************
// DÉBUT section vidéo
//*******************************************************************

.coin-video {
  min-height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: $arrondi-global;
}

.cell-video {
  padding-top: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-bottom: 0rem;
}

.responsive-embed-video {
  background-color: $black;
}

.overlay-container {
  position: relative; /* <-- Set as the reference for the positioned overlay */
}

.overlay-container .overlay {
  position: absolute; /* <-- Remove the overlay from normal flow         */
  bottom: 0;          /* <-- Keep the overlay at the bottom of the box   */
  left: 0;            /* <-- Set left and right properties to 0          */
  right: 0;           /*     In order to expand the overlay horizontally */
  top: 0;

  padding: 0.4rem;
}

//*******************************************************************
// FIN section vidéo
//*******************************************************************


//*******************************************************************
// DÉBUT section bas de page
//*******************************************************************

$couleur-bas-de-page: #57837a;

.bas-de-page {
  color: $couleur-bas-de-page;
  bottom: 0px;
  width:100%;
  min-height:300px;
  -webkit-background-size: cover cover;
  -moz-background-size: cover cover;
  -o-background-size: cover cover;
  background-size: cover cover;
  background-repeat: repeat-y;
  background-position: center;
  background: url('/assets/img/fond-bas-de-page.jpg');
}

.bas-de-page a {
  color: $couleur-bas-de-page;
}

.bas-de-page a:hover {
  color: rgba($couleur-bas-de-page, 0.8);
}

.bas-de-page ul .bottom-divider {
  border-bottom: none;
  border-top: none;
  border-right: solid 2px;
  clear: none;
  height: 36px;
  color: $couleur-bas-de-page;
}

//*******************************************************************
// FIN section bas de page
//*******************************************************************


//*******************************************************************
// DÉBUT section cartes
//*******************************************************************

.ma-carte-01 {
  min-height:300px;
  border: 1px solid $couleur-carte-contenu-bordure;
}

//*******************************************************************
// FIN section cartes
//*******************************************************************


//*******************************************************************
// DÉBUT section debug
//*******************************************************************

.grid-x-premiere {
  border: 6px solid #d00000;
  padding-top: 0;
  padding-bottom: 0;
}

.grid-x-seconde {
  border: 3px solid #ff0000;
  padding-top: 0;
  padding-bottom: 0;  
}

.grid-y-premiere {
  border: 6px solid #00d000;
  background: $black !important;
}

.grid-y-seconde {
  border: 3px solid #00ff00;
  padding-top: 244;
  padding-bottom: 0;
}

.ma-grille-dev {
  @include xy-grid;
  border: 6px solid #0000ff;
  padding-top: 0;
  padding-bottom: 0;  
}

.cell-dev {
  background: $black !important;
  border: 1px dashed #3600a2;
  padding-top: -1px;
  padding-bottom: 0px;  
  margin-top: -1px;
  margin-bottom: 0px;
}

.cell-corrige {
  padding-top: 244px;
}

.left-collapse {
  .columns {
    padding-left: 0;
    padding-right: 0;
  }
}

//*******************************************************************
// FIN section debug
//*******************************************************************


//FIN_FICHIER._
