// Définition des paramètres globaux spécifiques à chaque projet

// Paramètres généraux
$couleur-noir: #000000;
$couleur-blanc: #ffffff;
$couleur-theme-site: $couleur-noir;
$couleur-texte: #dce0e2;
$couleur-texte-negatif: #eefccf;
$couleur-fond-cadre: #964646;
$couleur-bordure: #2277c6;
$couleur-titre-bordure: #73AFE7;
$couleur-focus-souris: #94131b;

// Paramétres des orbits sliders d'images
$orbit-couleur-fleche: $couleur-texte;

// Paramètres des accordéons
$couleur-accordeon-titre-texte: $couleur-texte;
$couleur-accordeon-titre-fond: rgba($couleur-bordure, 0.75);
$couleur-accordeon-titre-bordure: $couleur-titre-bordure;
$couleur-accordeon-titre-focus-souris: rgba($couleur-accordeon-titre-fond, 0.5);
$couleur-accordeon-titre-actif-courant: $couleur-accordeon-titre-focus-souris;
$couleur-accordeon-contenu-texte: $couleur-texte;
$couleur-accordeon-contenu-bordure: $couleur-bordure;
$taille-texte-accordeon-titre: rem-calc(24);
$taille-texte-accordeon-contenu: rem-calc(18);

// Paramètres des tabulations
$couleur-tabulation-titre-texte: $couleur-texte;
$couleur-tabulation-titre-fond: rgba($couleur-bordure, 0.75);
$couleur-tabulation-titre-bordure: $couleur-titre-bordure;
$couleur-tabulation-titre-focus-souris: $couleur-tabulation-titre-bordure;
$couleur-tabulation-titre-actif-courant: rgba($couleur-tabulation-titre-bordure, 0.8);
$couleur-tabulation-contenu-texte: $couleur-texte;
$couleur-tabulation-contenu-bordure: $couleur-bordure;
$taille-texte-tabulation-titre: rem-calc(24);
$taille-texte-tabulation-contenu: rem-calc(18);

// Paramètres des cartes
$couleur-carte-contenu-bordure: $couleur-bordure;


//FIN_FICHIER._
